.container {
  /*padding: 0px 26px 26px;*/
  padding-bottom: 20px;
  width: 440px;
}

.title {
  margin: 20px auto;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}

.emailLoginContainer {
  width: 270px;
  margin: auto;
}

.forgotPassword {
  /*margin-top: 4px;*/
  font-size: 12px;
  font-weight: 600;
  color: grey;
  cursor: pointer;
}

.forgotPassword:hover {
  opacity: 0.7;
}

.or {
  margin: 10px auto;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

/*.icon {
  height: 24px;
  width: 24px;
  margin: 4px;
  padding: 2px;
  background-color: white;
  border-radius: 2px;
}*/

.button {
  color: white;
  display: flex;
  align-items: center;
  width: 270px;
  border-radius: 2px;
  margin: 10px auto;
  cursor: pointer;
}

.googleButton {
  background-color: #4285f4;
}

.googleButton:hover, .googleButton:active {
  background-color: #377ae6;
}

.facebookButton {
  background-color: #4267b2;
}

.facebookButton:active, .facebookButton:hover {
  background-color: #3b5da0;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin: 16px auto 10px auto;
}

.signUpButton {
  background-color: #03A9F4;
  text-align: center;
  width: 125px;
  margin: 0px;
  border-radius: 4px;
  font-weight: 600;
}

.signUpButton:active, .signUpButton:hover {
  background-color: #10a1e5;
}

.loginButton {
  background-color: #f0f0f0;
  color: #505050;
}

.loginButton:active, .loginButton:hover {
  background-color: #dddddd;
}

.buttonLabel {
  margin: 10px auto;
}

.privacyContainer {
  width: 270px;
  margin: 20px auto 10px auto;
  color: #a0a0a0;
  font-size: 12px;
}

.privacyContainer a {
  text-decoration: none;
  font-weight: 600;
  color: #a0a0a0;
}

.privacyContainer a:hover {
  opacity: 0.8;
}

.privacyContainer a:visited {
  color: #a0a0a0;
}

.error {
  width: 270px;
  margin: 10px auto 10px auto;
  color: #e60022;
  font-size: 12px;
}
