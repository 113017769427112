body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont,
    sans-serif;
  margin: 0px;
  overflow-x: auto;
  /*background-color: #f2f0ea;*/
  background-color: #f5f4ef;
}

p {
  margin: 0;
}

.display-linebreak {
  white-space: pre-line;
}

a {
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.75;
}